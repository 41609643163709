import React, { PureComponent } from 'react'
import { DsLoader } from '@am92/react-design-system'

export default class Loader extends PureComponent {
  render() {
    return (
      <DsLoader
        ds-variant='threeDot'
        color='actionPrimary'
        BackdropProps={{
          open: true,
          sx: theme => ({
            zIndex: theme.zIndex.appBar + 1
          })
        }}
      />
    )
  }
}
